.primary-carousel-card {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
  background-color: $bg-color-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding-left: 10px;
  padding-right: 5px;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;
    padding: 1rem;
    
    a {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      // background-color: red;
    }
    .banner-img {
      width: 40%;

      max-width: 15rem;
      .main-img {
        border-radius: 0.5rem;
        width: 100%;
        z-index: 100;
      }
    }
    .description {
      width: 60%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      // background: red;
      .title {
        color: #e3e3e3;
        padding-bottom: 2px;
        font-family: Poppins;
        font-size: 2.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .rating {
        margin-top: 1rem;
        height: 20px;
        display: flex;
        gap: 4px;
        align-content: center;
        flex-shrink: 0;
        color: #fff;
        font-family: Roboto;
        font-size: 1.15rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 31.113px */
      }

      .tags {
        margin-top: 1rem;
        display: flex;
        gap: 5px;
        overflow: hidden;
        flex-wrap: wrap;
        .tag {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 10px;
          border-radius: 0.25rem;
          background: #050100;
          color: #fff;
          color: var(--heading, #e0e0e0);
          text-align: center;
          font-family: Roboto;
          font-size: 0.8rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          min-width: 50px;
        }
      }

      .plays-subscribers {
        margin-top: 1rem;
        color: rgba(227, 227, 227, 0.5);
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      button {
        // position: relative;
        z-index: 100;
        margin-top: 1rem;
        justify-self: flex-end;
        border-radius: 0.3rem;
        background: #e3e3e3;
        color: #050100;
        font-family: Roboto;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 500;
        text-transform: capitalize;
        cursor: pointer;
        width: 15rem;
        outline: none;
        border: none;
      }
    }
    .glassmorphism-img {
      position: absolute;
      width: 50%;
      left: 3%;
      filter: blur(50px);
      z-index: 10;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .content {
      gap: 0.7rem;
      justify-content: space-between;
      .banner-img {
        margin-left: 5px;
        max-width: 13rem;
        height: 80%;
      }
      .description {
        box-sizing: border-box;
        overflow: hidden;
        padding: 0.6rem 0px;
        width: calc(100% - 160px);
        max-width: 50%;
        height: 95%;
        .title {
          width: 100%;
          color: #e3e3e3;
          font-family: Poppins;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
        }
        .rating {
          margin-top: 0.4rem;
          height: 1.2rem;
        }
        .tags {
          .tag {
            font-size: 0.7rem;
          }
        }
        .plays-subscribers {
          font-size: 0.7rem;
        }
        button {
          max-width: 100px;
          font-size: 0.7rem;
          font-weight: 600;
          font-family: Roboto;
          padding: 4px 20px;
        }
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    height: 100%;
    .content {
      width: 100%;
      gap: 0.7rem;
      .banner-img {
        margin-left: 0px;
        height: 95%;
      }
      .description {
        box-sizing: border-box;
        overflow: hidden;
        padding: 0.6rem 0px;
        // width: calc(100% - 150px - 10px);
        width: 50%;
        .title {
          width: 100%;
          color: #e3e3e3;
          font-family: Poppins;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
        }
        .rating {
          margin-top: 0.4rem;
          height: 1.2rem;
        }
        .tags {
          .tag {
            font-size: 0.7rem;
          }

          :last-child {
            display: none;
          }
        }
        .plays-subscribers {
          font-size: 0.8rem;
        }
        button {
          width: 100%;
          font-size: 0.8rem;
          font-weight: 600;
          font-family: Roboto;
        }
      }
    }
  }
}
