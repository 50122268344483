$square-card-width-height: 11rem;
$potrait-card-height: 16rem;
$potrait-card-width: 12rem;

.carousel-container-wrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
  margin-top: 5rem;
  .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      width: 70%;
      color: var(--heading, #e0e0e0);
      font-family: Poppins;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    a {
      width: 6.35rem;
      color: #fff;
      font-family: poppins;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      // text-decoration: underline;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .carousel-container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(
      --swiper-wrapper-transition-timing-function,
      initial
    );
    gap: 1rem;

    .swiper-slide {
      max-width: 13rem;
    }

    &:hover .slider-arrow {
      opacity: 1;
    }

    .slider-arrow {
      position: absolute;
      z-index: 100;
      transition: 0.2s;
      opacity: 0;
    }

    .custom-prev-button,
    .custom-next-button {
      height: 100%;
      width: 50px;
    }
    .custom-prev-button {
      cursor: pointer;
      top: 50%;
      left: -2px;
      transform: translateY(-50%);
    }
    .custom-next-button {
      cursor: pointer;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
    }
  }

  .top-10-carousel-container {
    // perspective: 1000px;
    .swiper-slide {
      max-width: 15rem;
      margin-left: 1rem;
      // transition: transform 0.3s;
      // transform: translateZ(-200px) rotateY(0deg);
      &:first-child {
        margin-left: 0;
      }

      // &:hover {
      //   transform: translateZ(300px) rotateY(10deg) scale(1.2);
      // }
    }
  }

  .potrait-carousel-container {
    // height: 21rem;
    .swiper-slide {
      max-width: 17rem;
      margin-left: 1rem;
      transition: 0.2s;
      &:first-child {
        margin-left: 0;
      }

      &:hover {
        max-width: 34rem;
      }

      &:last-child {
        &.potrait-carousel-card {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .grid-view-6-carousel-container {
    .swiper-slide {
      max-width: 30rem;
      margin-left: 1rem;
      transition: 0.2s;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .divider {
    height: 0.5px;
    width: 98%;
    background: #2a2a2a;
    margin: 1.25rem 0px;
  }

  @media (max-width: $breakpoint-mobile) {
    margin-top: 4.5rem;
    .heading {
      width: 100%;
      span {
        color: #e3e3e3;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      a {
        width: 4.35rem;
        font-size: 0.9rem;
      }
    }

    .carousel-container {
      width: 100%;
      gap: 10px;

      .slider-arrow {
        height: 4rem;
        width: 2.2rem;
      }
    }
  }
}

.square-carousel-card {
  // height: $square-card-width-height;
  width: 95%;
  max-width: $square-card-width-height;
  width: 12rem;
  max-width: 12rem;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  transition: 0.3s;
  img {
    border-radius: 2px;
    width: 100%;
  }

  p {
    width: 100%;
    margin-left: auto;
    padding-top: 7px;
    white-space: nowrap;
    overflow: hidden;
    color: var(--sub, #b8b8b8);
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  @include x-icon-style;
  .rating-plays {
    color: rgba(184, 184, 184, 0.7);
    font-family: Roboto;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01125rem;
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      width: 12px;
      height: 12px;
      transform: translateY(-2px);
    }
  }

  // &:hover {

  // }
}

.continue-carousel-card {
  width: 85%;
  cursor: pointer;
  position: relative;
  .img-wrapper {
    width: 100%;
    // display: flex;
    position: relative;
    img {
      border-radius: 4px;
      width: 100%;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        width: 50px;
      }
    }

    @include x-icon-style;

    .progress-slider {
      width: 100%;
      height: 3px;
      opacity: 0.5;
      margin-top: 2px;
      background: #b8b8b8;

      .progress {
        width: 0%;
        background-color: red;
        height: 3px;
        transition: 0.2s ease 0.2s ease;
      }
    }
  }

  p {
    margin-top: 8px;
    overflow: hidden;
    width: 100%;
    color: var(--sub, #b8b8b8);
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

.top-10-carousel-card {
  width: 80%;
  cursor: pointer;
  position: relative;

  &:first-child {
    margin-left: 3rem;
  }

  .img-wrapper {
    width: 100%;
    display: flex;
    position: relative;
    img {
      border-radius: 5px;
      width: 100%;
    }

    .number {
      position: absolute;
      z-index: 10;
      left: -25%;
      color: #fff;
      font-family: Roboto;
      font-size: 8.1rem;
      font-weight: 900;
      opacity: 1;
      line-height: 6rem;
      padding: 0px;
      bottom: 0px;
      color: #fff;
      text-shadow: 7.397px 7.397px 14.795px rgba(0, 0, 0, 0.655);
    }

    @include x-icon-style;
  }

  .title {
    width: 100%;
    margin-left: auto;
    padding-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    color: #b8b8b8;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.potrait-carousel-card {
  width: 100%;
  border-radius: 0.75rem;
  display: flex;
  gap: 1rem;

  .img-wrapper {
    // width: 16rem;
    height: 21rem;
    display: flex;
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    a {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
    }
    img {
      // width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }

    @include x-icon-style;
  }

  .potrait-card-content {
    width: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    overflow: hidden;
    p,
    h2 {
      padding: 0;
      margin: 0;
    }
    .title {
      color: #e3e3e3;
      font-family: Poppins;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      opacity: 0;
      display: -webkit-box;
      width: 95%;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rating-plays {
      color: rgba(184, 184, 184, 0.7);
      font-family: Roboto;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.01125rem;
      display: flex;
      align-items: center;
      gap: 5px;
      svg {
        width: 15px;
        height: 15px;
      }
      opacity: 0;
    }

    .about {
      overflow: hidden;
      color: var(--sub, #b8b8b8);
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 1.6875rem */
      display: -webkit-box;
      width: 95%;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      opacity: 0;
    }

    .btns {
      width: 90%;
      opacity: 0;
      display: grid;
      grid-template-columns: 70% 30%;
      align-items: center;
      gap: 6px;
    }
  }

  &:hover {
    background: #262525;
    .potrait-card-content {
      width: 50%;
      transition: all 300ms cubic-bezier(0.39, 0.575, 0.565, 1); /* easeOutSine */
      transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
      .title {
        animation: fadeInAnimation ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 0.2s;
      }
      .rating-plays {
        animation: fadeInAnimation ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 0.4s;
      }

      .about {
        animation: fadeInAnimation ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 0.6s;
      }

      .btns {
        animation: fadeInAnimation ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 0.8s;
      }
    }
  }
}

.publish-content-card {
  width: 11rem;
  max-height: 15rem;
  cursor: pointer;
  position: relative;
  transition: 0.3s;
  border-radius: 0.5rem;
  background: rgba(42, 42, 42, 0.85);
  backdrop-filter: blur(32.04999923706055px);
  padding: 10px;
  padding-bottom: 20px;
  overflow: hidden;
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    left: 0;
    top: 0;
  }
  .img-wrapper {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      max-height: 13rem;
      object-fit: cover;
      border-radius: 0.5rem;
    }

    @include x-icon-style;
  }
  .content-wrapper {
    .title {
      width: 95%;
      white-space: nowrap;
      color: #e3e3e3;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 500;
      text-transform: capitalize;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 0.5rem;
    }

    .rating-plays {
      width: 100%;
      margin-top: 0.5rem;
      color: rgba(184, 184, 184, 0.9);
      font-family: Roboto;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.01125rem;
      display: flex;
      align-items: center;
      white-space: noWrap;
      overflow: hidden;
      text-overflow: ellipsis;
      gap: 5px;
      svg {
        width: 13px;
        height: 13px;
        transform: translateY(-1px);
      }
    }

    .about {
      display: none;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      justify-content: center;
      .title {
        width: 100%;
        font-size: 0.9rem;
        white-space: noWrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #b8b8b8;
      }

      .rating-plays {
        width: 100%;
        white-space: noWrap;
      }

      .about {
        display: none;
      }
    }
    &.mobile-view {
      width: 100%;
      display: flex;
      gap: 1rem;
      max-height: 13rem;
      padding: 10px;
      background-color: transparent;
      border-bottom: 1px solid rgba(240, 240, 240, 0.2);
      padding-bottom: 15px;

      .img-wrapper {
        width: 30%;
        max-width: 12rem;
      }

      .content-wrapper {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        .title {
          width: 100%;
          font-size: 1rem;
          white-space: noWrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #f0f0f0;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        .rating-plays {
          width: 100%;
          white-space: noWrap;
          margin-top: 0;
        }

        .about {
          display: block;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          font-size: 0.9rem;
          font-family: Roboto;
          font-weight: 400;
          line-height: 1.2rem;
          color: #b8b8b8;
        }
      }
    }
  }
}
