.Three-d-carousel-container-wrapper {
  width: 95%;
  margin: auto;
  .Three-d-carousel-container {
    width: 100%;
    padding: 1rem 0 3rem 0;
    position: relative;

    .swiper-slide {
      box-sizing: border-box;
      width: 50%;
      height: 18rem;
      position: relative;
      border-radius: 0.75rem;
      background: #0f0f0f;
      color: white;
    }
    .slider-controler {
      .slider-arrow {
        position: absolute;
        z-index: 100;
      }
      .custom-prev-button,
      .custom-next-button {
        height: 80px;
        width: 40px;
      }
      .custom-prev-button {
        cursor: pointer;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
      }
      .custom-next-button {
        cursor: pointer;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }

      .swiper-pagination {
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        transition: 200ms;
        .swiper-pagination-bullet-active {
          border-radius: 15px;
          width: 25px;
          background-color: white;
        }
        .swiper-pagination-bullet {
          background-color: white;
        }
      }
    }
  }
  @media (max-width: $breakpoint-tablet) {
    .Three-d-carousel-container {
      .swiper-slide {
        width: 50%;
        height: 16rem;
        @media (max-width: "800px") {
          width: 60%;
        }
      }
      .slider-arrow {
        display: none;
      }

      .swiper-pagination {
        position: absolute;
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .Three-d-carousel-container {
      .swiper-slide {
        width: 100%;
        height: 15rem;
      }
      .slider-arrow {
        display: none;
      }

      .swiper-pagination {
        position: absolute;
      }
    }
  }
}
