.trending-container-wrapper {
    width: 90%;
    margin-top: 1rem;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 10px 0px;
    .heading {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #e3e3e3;
        font-family: Bebas Neue;
        // font-family: Poppins;
        font-size:2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      a {
        width: 6.35rem;
        color: #fff;
        font-family: Roboto;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: underline;
      }
    }
  
    .carousel-container {
      width: 100%;
      position: relative;
      .swiper-slide {
        height: auto;
        margin-right: 0.8rem;
        width: auto;
      }
  
      .slider-arrow {
        position: absolute;
        z-index: 100;
      }
  
      .custom-prev-button,
      .custom-next-button {
        height: 70px;
        width: 40px;
      }
      .custom-prev-button {
        cursor: pointer;
        top: 50%;
        left: -2px;
        transform: translateY(-50%);
      }
      .custom-next-button {
        cursor: pointer;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }
    }
  
    // .divider {
    //   height: 1.5px;
    //   width: 98%;
    //   background: #2a2a2a;
    //   margin: 1.25rem 0px;
    //   margin-top: 3.125rem;
    // }
  
    @media (max-width: $breakpoint-mobile) {
  
    
      .heading {
        width: 100%;
        
          span {
            color: #e3e3e3;
            font-family: Bebas Neue;
            font-size:1.7rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        a {
          width: 4.35rem;
          font-size: 0.9rem;
        }
      }
  
      .carousel-container {
        width: 100%;
        .swiper-slide {
          height: auto;
          width: auto;
        }
        .slider-arrow {
          height: 4rem;
          width: 2rem;
        } 
      }
    }
  }
  
  .dynamic-carousel-card {
    width: 100%;
    height: 100%;
  
    img {
      border-radius: 2px;
      height: 80%;
      width: 100%;
    }
  
    p {
      width: 100%;
      margin-left: auto;
      padding-top: 7px;
      white-space: nowrap;
      overflow: hidden;
      color: #e3e3e3;
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  