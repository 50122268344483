.episode-listing {
  width: 98%;
  color: white;
  box-sizing: border-box;
  height: calc(100vh - var(--navbar-height) - 10px);
  display: grid;
  grid-template-columns: 48% 50%;
  gap: 2%;
  margin: auto;
  margin-bottom: 1rem;

  .left {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 0.8rem;
    overflow: hidden;
    .back-icon {
      button {
        padding: 0px;
        margin: 0px;
        cursor: pointer;
      }
    }

    .content {
      padding-right: 10px;
      width: 90%;
      height: 95%;
      overflow: hidden;
      color: #e3e3e3;
      font-family: Poppins;
      font-size: 1.2rem;
      font-weight: 600;
      margin: auto;

      .top {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        .img {
          width: 40%;
          min-width: 12rem;
          // max-width: 15rem;
          position: relative;
          border-radius: 0.5rem;
          overflow: hidden;

          @include x-icon-style;
          .x-icon {
            transform: scale(1.2);
          }

          img {
            width: 100%;
            border-radius: 0.5rem;
          }
        }
        .title-rating-wrapper {
          width: 100%;
          .rating {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;

            svg {
              width: 20px;
              height: 20px;
              transform: translateY(-2px);
            }
          }

          .title {
            width: 50%;
            color: #e3e3e3;
            font-family: Poppins;
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            line-height: 106%; /* 42.4px */
            margin-top: 1rem;
          }
        }
      }

      .plays-subscribers {
        margin-top: 1rem;
        color: #b8b8b8;
        font-family: Roboto;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: space-between;

        .rating {
          display: none;
          align-items: center;
          gap: 5px;
          svg {
            width: 15px;
            height: 15px;
            transform: translateY(-1px);
          }
        }
      }

      .buttons {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-top: 1rem;

        button {
          width: 100%;
          border-radius: 3px;
          background: #e3e3e3;
          color: #050100;
          font-family: Roboto;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          text-transform: capitalize;
          max-width: 200px;
          height: 40px;
        }

        .subscribe-btn {
          color: white;
          border-radius: 0.25rem;
          background: #383838;
        }
      }

      .genre {
        margin-top: 1.5rem;
        display: flex;
        gap: 8px;
        // flex-wrap: wrap;
        overflow-x: scroll;
        .tag {
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          padding-right: 10px;
          color: #b8b8b8;
          font-family: Roboto;
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 24.123px */
          border-right: 0.6px solid #b8b8b8;

          &:first-child {
            padding-left: 0;
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .desciption {
        margin-top: 1.5rem;
        color: #e3e3e3;
        font-family: Poppins;
        font-style: normal;
        line-height: normal;
        padding-bottom: 2rem;
        position: relative;
        padding-right: 1rem;
        width: 100%;
        p {
          display: box;
          overflow: hidden;
          line-clamp: 4;
          box-orient: vertical;
          text-overflow: ellipsis;
          margin-top: 10px;
          width: 100%;
          color: #b8b8b8;
          font-family: Roboto;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          opacity: 0.8;
        }

        .read-more-btn {
          color: white;
          position: absolute;
          right: 0;
          bottom: 0;
          width: auto;
          cursor: pointer;
        }
      }

      .cast-and-crew {
        display: none;
        // margin-top: 10px;
        color: #e3e3e3;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        button {
          height: 35px;
          &:hover {
            background-color: rgba(213, 255, 254, 0.683);
          }
        }
      }
    }
  }

  .right {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    .cast-and-crew {
      color: #e3e3e3;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      button {
        &:hover {
          background-color: rgba(213, 255, 254, 0.683);
        }
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        color: #e3e3e3;
        font-family: Poppins;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      button {
        color: #fff;
        font-family: Roboto;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;

        svg {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }

    .episodes-listing-cards {
      height: 100%;
      overflow: hidden;
      display: grid;
      overflow-y: auto;
      margin-top: 0.9rem;
      padding-right: 20px;
      gap: 0.9rem;
    }
    .sticky-header {
      z-index: 10 !important;
    }

    .MuiTab-root,
    h2 {
      color: rgba(224, 224, 224, 0.5);
      text-align: center;
      font-family: Roboto;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 1.1375rem */
      letter-spacing: 0.035rem;
      text-transform: uppercase;
      padding: 5px 8px;
      // width: 100px;
      // max-width: 150px;
      margin-right: 10px;

      &.Mui-selected > h2 {
        color: #e0e0e0;
      }
    }

    .MuiTabs-indicator {
      background: #e0e0e0;
      width: 90%;
      max-width: 90%;
    }

    .MuiTabPanel-root {
      height: 95%;
      overflow: hidden;
      gap: 1rem;
      overflow-y: auto;
      margin-top: 0.9rem;
      padding-right: 20px;
      gap: 0.9rem;
      padding-bottom: 50px;
    }

    .more-like-this {
      width: 100%;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      // justify-content: center;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .left {
      .content {
        width: 95%;
        .top {
          gap: 0.8rem;
          img {
            width: 10rem;
            height: 11rem;
          }

          .describe {
            width: calc(100% - 10rem);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .title {
            font-size: 1.8rem;
          }

          button {
            white-space: nowrap;
            font-size: 0.9rem;
            overflow: hidden;
            span {
              margin-right: 2px;
              transform: scale(0.8);
            }
          }
          .subscribe-btn {
            color: white;
            background: transparent;
            border: 1px solid #e3e3e3;
          }
        }

        .desciption {
          margin-top: 20px;
          font-size: 1.2rem;

          p {
            margin-top: 10px;
            width: 100%;
            text-overflow: ellipsis;
            font-size: 1rem;
          }
        }

        .cast-and-crew {
          margin-top: 20px;

          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: auto;
    width: 95%;
    margin: auto;
    height: auto;
    gap: 20px;
    margin-top: 1rem;
    .left {
      flex-direction: column;
      gap: 10px;
      padding: 0px;
      .back-icon {
        display: none;
      }
      .content {
        width: 100%;
        margin-left: 0px;
        height: auto;
        gap: 1rem;
        .top {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          .img {
            width: 100%;
            img {
              width: 100%;
              height: auto;
            }
            .x-icon {
              svg {
                width: 40px;
                height: 40px;
              }
            }
          }
          .title-rating-wrapper {
            width: 100%;
            .rating {
              display: none;
            }
            .title {
              width: 100%;
            }
          }
        }

        .genre {
          margin-top: 1rem;
        }

        .buttons {
          width: 100%;
          gap: 2%;
          button {
            max-width: 49%;
            width: 49%;
          }
        }
        .plays-subscribers {
          .rating {
            transform: scale(1.1);
            display: flex;
          }
        }

        .cast-and-crew {
          display: none;
        }
        .show {
          display: block;
        }
      }
    }
    .right {
      width: 100%;
      height: auto;
      .MuiTab-root {
        max-width: auto;
        width: 31%;
        white-space: noWrap;
        // max-width: 100px;
      }

      .MuiTabPanel-root {
        overflow: hidden;
        height: auto;
        padding-right: 0px;
      }

      .cast-and-crew {
        height: calc(100vh - 200px);
        overflow-y: scroll;

        button {
          &:hover {
            background-color: transparent;
            color: White;
          }
        }
      }
    }
  }
}
