.login-modal,
.modal {
  height: 100%;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .content,
  .registration-content {
    width: 75%;
    margin: auto;

    h3 {
      color: #f0f0f0;
      font-family: Poppins;
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 68.976px */
      text-align: left;
    }

    h5 {
      justify-self: flex-start;
      text-align: center;
      width: 60%;
      margin: auto;
      color: #f0f0f0;
      font-family: Poppins;
      font-size: 1.6rem;
    }

    .phone-number {
      box-sizing: border-box;
      margin: auto;
      margin-top: 30px;
      width: 100%;
      display: flex;
      padding: 0.9rem 1rem;
      align-items: center;
      gap: 10px;
      border-radius: 6.632px;
      border: 1px solid #fff;

      span {
        color: #fff;
        font-family: Roboto;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 500;
      }
      input {
        height: 100%;
        width: 80%;
        outline: none;
        border: none;

        color: #fff;
        font-family: Roboto;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        background-color: transparent;

        // border-radius: 0.5rem;

        &::placeholder {
          color: rgba(224, 224, 224, 0.3);
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 1.625rem */
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.error {
        border-color: red;
      }
    }
    .btn {
      width: 100%;
      margin: auto;
      margin-top: 15px;

      display: flex;
      align-items: center;

      svg {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
      &.facebook-btn {
        button {
          font-weight: 500;
          color: white;
          letter-spacing: 0.5px;
          background: #1778f2;
        }
      }

      &.google-btn {
        button {
          font-weight: 500;
          color: white;
          letter-spacing: 0.5px;
          background: #ea4235;
        }
      }
      button {
        text-transform: capitalize;
        outline: none;
        border: none;
        border-radius: 3px;
        width: 100%;
        padding: 0.8rem 1rem;
        background-color: rgb(255, 255, 255);
        color: #050100;
        text-align: center;
        font-family: Poppins;
        font-size: 1.2rem;
        font-style: normal;
        line-height: 130%; /* 45.984px */
        align-self: center;
        justify-self: flex-end;
        font-weight: 500;
        &:disabled {
          background: rgba(224, 224, 224, 0.4);
        }
      }
    }
    .or {
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      span {
        margin: 0px 10px;
        color: #fff;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 45.984px */
      }
      .leftline {
        height: 2px;
        width: calc(80% - 40% - 20px);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(253, 253, 253, 0.81) 100%
        );
      }
      .rightline {
        height: 2px;
        width: calc(80% - 40% - 20px);
        background: linear-gradient(
          90deg,
          rgba(253, 253, 253, 0.81) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }

  .terms-conditions {
    width: 80%;
    margin: 0px auto;
    text-align: center;
    margin-top: 30px;
    color: #9f9f9f;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    a {
      text-align: center;
      color: #9f9f9f;
      font-family: Roboto;
      font-size: 1rem;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .content {
      width: 90%;
    }
    .Otp-modal {
      width: 90%;
    }
  }
}

.Otp-modal {
  width: 80%;
  margin: auto;

  h3 {
    width: 16rem;
    margin: auto;
    text-align: center;
    color: #f0f0f0;
    font-family: Poppins;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 68.976px */
  }

  p {
    width: 90%;
    margin: auto;
    text-align: center;
    margin-top: 5px;
    color: #8e8e8d;
    text-align: center;
    font-family: Roboto;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3rem;

    span {
      cursor: pointer;
      color: var(--blue, #24a0ed);
    }
  }
  .eight-otp-wrp {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .resend-otp {
    color: #24a0ed;
    font-family: Roboto;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    margin-top: 0.75rem;
    margin-bottom: 3rem;
    margin-left: 5%;
  }

  .btn {
    width: 100%;

    margin: auto;
    display: flex;
    align-items: center;

    button {
      text-transform: capitalize;
      outline: none;
      border: none;
      border-radius: 3px;
      width: 100%;
      background-color: white;
      color: #050100;
      text-align: center;
      font-family: Poppins;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 600;

      &:disabled {
        background: rgba(224, 224, 224, 0.4);
      }
    }
  }
  .otp-error {
    width: 90%;
    display: flex;
    align-items: center;
    color: var(--wrong-warning, #ff8a00);
    margin-top: 6px;
    @media (max-width: $breakpoint-mobile) {
      width: 65%;
    }
  }
}

.eight-otp-wrp {
  // width: 100%;
  &.error,
  input {
    border-color: var(--wrong-warning, #ff8a00);
  }
  input {
    width: 3rem;
    height: 3.5rem;
    font-size: 1.5rem;
    text-align: center;
    margin: 0px 5px;
    text-transform: uppercase;
    color: #fff;
    background: #2b2b2b;
    background-clip: padding-box;
    border-radius: 12px;
    border: 0.5px solid white;
    font-family: $font-primary;
    font-weight: 700;
    // margin-bottom: 30px;

    @media (max-width: $breakpoint-mobile) {
      width: 2.5rem;
      height: 3rem;
      margin: 0px 0px;
    }

    @media (max-width: 1200px) {
      margin-right: 8px;
    }

    &:focus {
      border: 1px solid #ce2a2a;
      transform: scale(1.1);
      transition: 0.3s;
    }
  }
}
