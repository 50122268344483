.episode-listing-card {
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #212121;
  color: white;
  padding: 1.2rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  .episode-details {
    height: 100%;
    width: 100%;
    display: grid;
    gap: 0.7rem;

    .top {
      width: 100%;
      display: flex;
      gap: 1rem;
      align-items: center;
      img {
        width: 3.5rem;
        border-radius: 0.2rem;
      }
      .img-wrapper {
        position: relative;
        width: 3.5rem;
        height: 3.5rem;
        img {
          width: 3.5rem;
          border-radius: 0.2rem;
        }
        .lock-icon-wrapper {
          width: 100%;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          border-radius: 0.375rem;
          background: linear-gradient(
            0deg,
            rgba(66, 66, 66, 0.57) 0%,
            rgba(66, 66, 66, 0.57) 100%
          );

          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 15px;
          }
        }
      }

      .name {
        display: grid;
        gap: 5px;

        p {
          color: var(--subheading, #b8b8b8);
          font-family: Roboto;
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 1.3rem */
          display: flex;
          width: 130%;
          justify-content: space-between;
        }
        h3 {
          width: 100%;
          color: var(--heading, #e0e0e0);
          font-family: Roboto;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0.5px;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .description {
      display: flex;
      width: 90%;
      color: var(--sub, #b8b8b8);
      font-family: Roboto;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      flex-direction: column;
      div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.5s;
      }
      p,
      a {
        font-size: 0.9rem;
        color: var(--sub, #b8b8b8);
        font-family: Roboto;
        cursor: pointer;
        display: inline;
        align-self: flex-end;
        pointer-events: none;
      }
    }

    .premium-text-mobile {
      color: #f1b419;
      font-family: Roboto;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 146%; /* 1.6425rem */
      display: none;
    }

    .footer {
      position: relative;
      align-items: center;
      display: flex;
      gap: 10px;
      padding: 10px 0px;
      justify-content: space-between;
      button {
        padding: 0px;
        margin: 0px;
      }
      .premium-text {
        color: #f1b419;
        font-family: Roboto;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: 146%; /* 1.6425rem */
      }

      .date-playBtn {
        display: flex;
        align-items: center;
        gap: 10px;
        .date-time,
        .date-time-mobile {
          display: flex;
          gap: 10px;
          text-wrap: nowrap;
          color: var(--subheading, #b8b8b8);
          font-family: Roboto;
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.01125rem;
        }
      }
      .share-like-btn {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      @media (max-width: $breakpoint-mobile) {
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 12px 15px;
    width: 100%;
    margin: auto;
    margin-top: 10px;
    .episode-details {
      .premium-text-mobile {
        display: block;
      }
      .top {
        .name {
          width: 60%;
          overflow: hidden;
        }
      }

      .footer {
        flex-direction: row-reverse;
        .premium-text {
          display: none;
        }
        .date-playBtn {
          flex-direction: row-reverse;
          width: 60%;
          .date-time {
            flex-direction: row-reverse;
            .date {
              display: none;
            }
          }
          .progress-slider {
            width: 80% !important;
          }
        }

        .share-like-btn {
          flex-direction: row-reverse;
        }
      }
    }
  }
}
