.genre-listing-page {
  width: 95%;
  margin: auto;
  min-height: calc(100vh - var(--navbar-height) - 10px);
  // height: 100vh;
  .banner {
    height: 11rem;
    margin-top: 1.3rem;
    position: relative;
    // position: sticky;
    // top: calc(var(--navbar-height) - 10px);
    .overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.24);
      position: absolute;
      top: 0;
      border-radius: 0px 0px 20rem 0px;
      transition: 0.5s;
      box-shadow: 0 0 10px 0;
    }

    h2 {
      text-align: center;
      color: white;
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-shadow: 0.35125768184661865px 1.4050307273864746px
          1.9185113906860352px rgba(0, 0, 0, 0.671),
        2.2382633686065674px 4.215091705322266px 5.620122909545898px
          rgba(0, 0, 0, 0.815);
      font-family: Poppins;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      background: linear-gradient(180deg, #fff 0%, #bebebe 100%);
      background-clip: text;
    }

    img {
      height: 100%;
      width: 100%;
      top: 0;
      z-index: 1;
      position: absolute;
    }
  }

  .shows-container {
    width: 80%;
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-top: 2rem;
    .publish-content-card {
      width: 9.5rem;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    width: 98%;
    .banner {
      h2 {
        font-size: 2rem;
      }
    }
  }
}

.genrePage {
  width: 95%;
  margin: auto;
  margin-top: 10px;
  min-height: calc(100vh - var(--navbar-height) - 20px);
  .genre-listing-card-wrapper {
    margin-top: 1rem;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(5, minmax(13rem, 1fr));
    gap: 12px;
    .genre-listing-card {
      height: 8rem;
      position: relative;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      overflow: hidden;

      h3 {
        z-index: 1;
        color: #f0f0f0;
        text-align: center;
        font-family: Poppins;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 145%; /* 2.175rem */
      }
      h4 {
        z-index: 1;
        text-align: center;
        color: #f0f0f0;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 1.625rem */
        text-transform: capitalize;
      }

      .letter-img {
        position: absolute;
        left: -10px;
      }
      .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 0;
        border-radius: 0px 0px 90px 0px;
        transition: 0.5s;
      }

      &:hover {
        .overlay {
          box-shadow: -10px -3px 15px 0px rgba(0, 0, 0, 0.24) inset,
            5px 4px 14px 0px rgba(0, 0, 0, 0.08) inset;
        }
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    width: 98%;

    .genre-listing-card-wrapper {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
