.grid-view-six-carousel-card {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas:
    "main main img-1"
    "main main img-2";
  .img-1 {
    grid-area: img-1;
  }
  .img-2 {
    grid-area: img-2;
  }
  .main {
    grid-area: main;
  }
  img {
    width: 100%;
    border-radius: 4px;
  }
}
