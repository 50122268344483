.modal-wrapper {
  position: absolute;
  width: 30rem;
  height: 35rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24;
  padding: 2;
  border-radius: 0.5rem;
  background: #1b1b1b;
  //   box-shadow: 55px -22px 44px rgba(1, 1, 1, 0.63);
  outline: none;
  color: white;

  .modal-content {
    height: 90%;
    width: 80%;
    display: grid;
    margin: 0 auto;
    margin-top: 2rem;
    .user-name {
      color: #fff;
      font-family: Poppins;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      margin: 0;
      padding: 0;
    }

    .content-img {
      width: 80%;
      max-width: 17rem;
      border-radius: 1.375rem;
      margin: 0 auto;
    }

    .msg {
      width: 80%;
      margin: 0;
      padding: 0;
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 146%; /* 1.6425rem */
      margin: 0 auto;
    }

    .btn-wrapper{
        width: 90%;
        margin: auto;
        @include bg-white-btn;
    }
  }
}
