// COLOR VARIABLES

$color-primary: #fff;
$color-secondary: #e3e3e3;
$color-grey-light-1: rgba(255, 255, 255, 0.5);
$color-grey-light-2: rgba(255, 255, 255, 0.78);
$color-grey-light-3: rgba(255, 255, 255, 0.76);
$color-gradient: linear-gradient(
  257.44deg,
  #f27121 0%,
  #e94057 49.32%,
  #8a2387 100%
);

// BG VARIABLES
$bg-color-primary: #050100;
// $bg-color-secondary: #2A2A2A;
$bg-color-secondary: #0f0f0f;
$bg-color-3: #212121;

// FONT VARIABLES
$font-primary: "SF Pro Display", sans-serif;
$font-display: "Nekst", sans-serif;

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; // 1200px
$bp-large: 62.5em; // 1000px
$bp-medium: 50em; // 800px;
$bp-small: 37.5em; // 600px;

$breakpoint-sm-laptop: 1300px;
$breakpoint-tablet: 1024px;
$breakpoint-mobile: 750px;
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.play-button {
  z-index: 10 !important;
  background: #e3e3e3 !important;
  color: #050100 !important;
  font-family: Roboto !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  width: 100% !important;
  outline: none !important;
  border: none !important ;
  padding: 5px 10px !important;
  border-radius: 0.25rem;
}

.subscribe-btn {
  margin-top: 0px !important;
  color: white;
  border-radius: 0.25rem;
  background: #383838;
  border: none;
  padding: 5px 10px !important;

  z-index: 10 !important;
  font-family: Roboto !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  // width: 100% !important;
  outline: none !important;
  border: none !important ;
  border-radius: 0.25rem;

  svg {
    width: 25px;
    height: 25px;
  }
}

@mixin bg-white-btn {
  button {
    z-index: 10 !important;
    background: #e3e3e3 !important;
    color: #050100 !important;
    font-family: Roboto !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    cursor: pointer !important;
    width: 100% !important;
    outline: none !important;
    border: none !important ;
    padding: 5px 10px !important;
    border-radius: 0.25rem;
  }
}

@mixin x-icon-style {
  .x-icon {
    position: absolute;
    top: -2px;
    left: -1px;
    width: 25px;
    height: 25px;

    svg {
      width: 100%;
      height: 100%;
      padding: 0px;
      background-color: red;
      border-radius: 0px 0px 35% 0px;
      img {
        overflow: hidden;
      }
    }
  }
}
