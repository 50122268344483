@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&family=Poppins:ital,wght@0,300;0,600;1,100;1,300;1,700;1,800&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,800&family=Roboto:ital,wght@0,400;0,500;0,600;0,700;0,900;1,300;1,700;1,900&display=swap");

@import "styles/constants";
@import "styles/navbar";
@import "styles/sidebar";
@import "styles/3dcarousel";
@import "styles/primary-carousel-card";
@import "styles/simple_carousel";
@import "styles/musicPlayer";
@import "styles/loginModal";
@import "styles/episode_listing";
@import "styles/cast-and-crew-badge";
@import "styles/episode-listing-card";
@import "styles/search-page";
@import "styles/trending-carousel-card";
@import "styles/trending-carousel";
@import "styles/view-all-page";
@import "styles/footer";
@import "../src/components/Navbar/navbarMenu";
@import "../src/components/Navbar/bottomNavbar";
@import "../src/components/Navbar/bottomNavbar";
@import "styles/genre-listing-page";
@import "./components/Card/NewSquareCarouselCard.scss";
@import "./components/Card/GridViewSixCarouselCard.scss";
@import "./components/Carousel/grid-view-eight-carousel.scss";
@import "./components/Modal//premiummodal.scss";

:root {
  --sidebar-width: 0px;
  --music-player-height: 0px;
  --navbar-height: 4rem;
  font-size: 16px;
  --bottom-navigation: 0;
  a {
    text-decoration: none;
  }
  @media (max-width: $breakpoint-sm-laptop) {
    --bottom-navigation: 0;
    font-size: 14px;
  }
  @media (max-width: $breakpoint-tablet) {
    --bottom-navigation: 56px;
    font-size: 13px;
  }

  @media (max-width: $breakpoint-mobile) {
    --bottom-navigation: 56px;
    font-size: 13px;
    --sidebar-width: 0;
  }
}

body {
  scroll-behavior: smooth;
  background-color: $bg-color-primary;
  user-select: none;
  #EightQrCode canvas {
    width: 100%;
    border-radius: 10px;
    height: 100%;
  }
  .main-content {
    width: calc(100vw - var(--sidebar-width));
    max-width: 2000px;
    margin: auto;
    margin-top: 6rem;
    background-color: $bg-color-primary;
    transition: 200ms;

    @media (max-width: $breakpoint-mobile) {
      margin-top: 0px;
    }

    .scrollbar-wrapper {
      position: relative;
      overflow: hidden;
      height: 100%; /* Adjust as needed */
      width: 100%; /* Adjust as needed */
    }
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background: #b8b8b8;
    }
    ::-webkit-scrollbar-track {
      border-radius: 0.6875rem;
      background: rgba(184, 184, 184, 0.2);
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.icon-wrapper {
  display: inline-block; /* Ensure the wrapper takes the size of the SVG */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.icon-wrapper.liked {
  color: #d30000; /* Set the color to red when liked */
}

.progress-slider {
  width: 100%;
  height: 3px;
  opacity: 0.5;
  margin-top: 2px;
  background: #b8b8b8;

  .progress {
    width: 0%;
    background-color: red;
    height: 3px;
    transition: 0.2s ease 0.2s ease;
  }
}
