.profile-badge {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 50px auto auto;
  gap: 0.5rem;
  align-items: center;
  background-color: #212121;
  padding: 1rem;
  border-radius: 0.5rem;
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  h4 {
    color: #fff;
    font-family: Roboto;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  p {
    margin-top: 2px;
    color: rgba(255, 255, 255, 0.7);
    font-family: Roboto;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  button {
    box-sizing: border-box;
    width: 110px;
    padding: 0px 25px;
    height: 35px;
    border-radius: 3px;
    background: transparent;
    color: #ece6e5;
    border-radius: 0.35rem;
    border: 1px solid #fff;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    justify-self: flex-end;

    &.following {
      border-radius: 0.25rem;
      border: 2px solid #b8b8b8;
      color: #b8b8b8;
    }

    &:hover {
      background-color: red;
      // color: red
      border: 2px solid #b8b8b8;
      color: black;
    }
  }
}
