.trending-carousel-card{
    width: 50rem;
    height: 16.79469rem;
    border-radius: 0.36375rem;
    border: 0.485px solid #2A2A2A;
    background: #0F0F0F;
    box-sizing: border-box;
    margin: 0px 10px;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding: 0px 20px;
    overflow: hidden;
    .index{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        color: #FFF;
        font-family: Poppins;
        font-size: 7.14631rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        opacity: 0.3;
    }

    .image{
        width:30%;
        height: 90%;
        border-radius: 0.18188rem;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            z-index: 10;
        }
        .overlay{
            position: absolute;
            left: 0;
            transform: scale(0.5);
            filter: blur(100px);
        }
    }
    .description{
        display: grid;
        width: 55%;
        .exclusive{
            color: #E3E3E3;
            font-family: Bebas Neue;
            font-size: 1.27094rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            span{
                color: #CE2A2A;
            }
        }

        .title{
            padding-bottom: 3px;
            color: #E3E3E3;
            font-family: Poppins;
            font-size: 2.37525rem;
            font-style: normal;
            font-weight: 500;
            line-height: 110%; /* 2.51775rem */
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .rating{
            height: 20px;
            display: flex;
            gap: 4px;
            align-content: center;
            flex-shrink: 0;
            color: #FFF;
            font-family: Roboto;
            font-size: 1.15rem;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 31.113px */
        }

        .tags{
            margin-top: 0.6rem;
            display: flex;
            gap: 5px;
            .tag{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 3px 10px;
                border-radius: 3.231px;
                background: #222;
                color: #FFF;
                font-family: Roboto;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 24.123px */
            }

           
        }
        button{
            margin-top: 0.5rem;
            background-color: white;
            display: inline-flex;
            padding: 0.6rem 3.78038rem;
            justify-content: center;
            align-items: center;
            gap: 0.37806rem;
            color: #050100;
            font-family: Roboto;
            font-size: 1.1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }
    }
}