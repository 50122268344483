.new-square-carousel-card-wrapper {
  position: relative;
}
.new-square-carousel-card {
  position: relative;
  width: 11rem;

  img {
    width: 100%;
    border-radius: 4px;
  }
  .x-icon {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .playButton {
    display: none;
  }

  .title {
    display: none;
  }

  .raing-plays {
    display: none;
  }

  .about {
    display: none;
  }
}
