.grid-view-eight-carousel-container {
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 4rem;
  margin-top: 5rem;
  .slider-arrow {
    width: 60px;
  }
  .grid-view-eight-carousel {
    max-width: 18rem;
    .swiper-slide {
      width: 18rem;
      max-width: 18rem;
      img {
        width: 95%;
        border-radius: 5px;
      }
    }
    .slider-arrow {
      cursor: pointer;
    }
  }

  .shows-info {
    width: 50%;
    position: relative;
    height: 60%;
    .carousel-title {
      color: #f9f9f9;
      font-family: Roboto;
      font-size: 2.2rem;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
      white-space: noWrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      top: 0;
      display: none;
    }

    .title {
      margin-top: 2rem;
      color: #f9f9f9;
      font-family: Poppins;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
      white-space: noWrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .about {
      margin-top: 0.5rem;
      color: #f9f9f9;
      font-family: Poppins;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 300;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      opacity: 0.9;
    }

    .rating-plays {
      color: #b8b8b8;
      opacity: 0.9;
      margin-top: 1rem;
      font-family: Roboto;
      font-size: 0.9rem;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.01125rem;
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        width: 15px;
        height: 15px;
      }
    }

    .btn {
      position: absolute;
      bottom: 1rem;
      width: 150px;
      z-index: 10;
    }
  }
}

.grid-view-8-carousel-mobile {
  width: 95%;
  margin: auto;
  margin-top: 4.5rem;
  .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem auto;
    span {
      width: 70%;
      color: var(--heading, #e0e0e0);
      font-family: Poppins;
      font-size: 1.7rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    a {
      width: 6.35rem;
      color: #fff;
      font-family: poppins;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      // text-decoration: underline;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .grid-view-8-carousel-container-mobile {
    .grid-view-8-carousel-card-eight {
      width: 100%;
      position: relative;
      display: grid;
      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .img-wrapper {
        width: 100%;
        img {
          width: 100%;
        }

        @include x-icon-style;
        .x-icon {
          width: 50px;
          height: 50px;
        }
      }
      .title {
        color: #e0e0e0;
        font-family: Roboto;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 135%;
        text-transform: capitalize;
        margin-top: 0.5rem;
        white-space: noWrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .rating-plays {
        margin-top: 0.5rem;
        color: rgba(184, 184, 184, 0.826);
        font-family: Roboto;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.01125rem;
        display: flex;
        align-items: center;
        gap: 4px;
        svg {
          width: 14px;
          height: 14px;
          transform: translateY(-2px);
        }
      }

      .about {
        margin-top: 0.5rem;
        overflow: hidden;
        color: var(--sub, #b8b8b8);
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 1.6875rem */
        display: -webkit-box;
        width: 95%;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}
