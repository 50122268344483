.music-player-wrapper {
  margin: auto;
  audio {
    display: none;
  }

  .music-player {
    height: var(--music-player-height);
    width: calc(100% - var(--sidebar-width));
    width: 95%;
    border-radius: 12.5rem;
    background: rgba(33, 33, 33, 0.4);
    box-shadow: 0px 5px 11.6px 0px rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(50px);
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
    z-index: 1000;
    .timeline-slider {
      width: calc(100% - 6.25rem);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -9px;
      .MuiSlider-root {
        border-radius: 10px;
        padding: 0;
      }
      .MuiSlider-colorPrimary {
        color: #ce2a2a;
        height: 3px;
        border-radius: 1px;
        .MuiSlider-rail {
          background: #595959;
        }
        .MuiSlider-thumb {
          width: 13px;
          height: 13px;
          color: white;
          border: 2px solid #212121;

          &:hover {
            background-color: none;
          }
        }
      }
    }

    .laptop-music-player {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: max-content auto auto auto;
      align-items: center;
      color: white;
      z-index: 100;
      bottom: 0;
      svg {
        transform: scale(0.96);
      }
      .time {
        color: #fff;
        font-family: Roboto;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 1rem;
      }

      .description {
        display: flex;

        gap: 10px;
        align-items: center;
        max-width: 25rem;
        img {
          width: 45px;
          height: 45px;
          flex-shrink: 0;
          border-radius: 7px;
        }

        div {
          height: 100%;
          width: calc(100% - 70px);

          h3 {
            overflow: hidden;
            white-space: nowrap;
            color: #fff;
            font-family: Roboto;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            width: 100%;
            margin-top: 5px;
            color: #b8b8b8;
            font-family: Roboto;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .control-buttons {
        justify-self: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transform: scale(0.95);
      }

      .right-block {
        justify-self: flex-end;
        display: flex;
        align-items: center;
        margin-right: 20px;
        gap: 25px;

        transform: scale(0.9);
        .volume-bar {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 13rem;

          .MuiSlider-colorPrimary {
            color: white;
            border-radius: 1px;
            .MuiSlider-rail {
              background: #595959;
            }
            .MuiSlider-thumb {
              opacity: 0;
            }
          }
        }
      }
    }

    .mobile-music-player {
      display: none;
    }

    @media (max-width: $breakpoint-tablet) {
      .laptop-music-player {
        .time {
          width: 80px;
        }
        .description {
          max-width: 15rem;
        }
        .right-block {
          .volume-bar {
            width: 9rem;
          }
        }
      }
    }

    @media (max-width: $breakpoint-mobile) {
      width: 100%;

      margin-bottom: 40px;
      .laptop-music-player {
        display: none;
      }
      .mobile-music-player {
        width: 100%;
        height: 100%;
        display: flex;
        color: white;
        align-items: center;
        justify-content: space-between;
        background: rgba(33, 33, 33, 0.4);
        box-shadow: 0px 5px 11.6px 0px rgba(0, 0, 0, 0.37);
        backdrop-filter: blur(50px);
        .timeline-slider {
          top: -8px;
          width: 90%;

          .MuiSlider-colorPrimary {
            height: 3px;
            border-radius: 0px;
          }
        }

        .description {
          display: flex;
          gap: 8px;
          margin: 0px 5px;
          height: 60%;
          align-items: center;
          width: 70%;

          img {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 7px;
          }

          div {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 6px;
            h3 {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: #fff;
              font-family: Roboto;
              font-size: 1.3rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              width: calc(100% - 45px);
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .time {
              color: #fff;
              font-family: Roboto;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .right-block {
          display: flex;
          gap: 1.3rem;
          margin-right: 10px;
          button {
            padding: 0px;
          }
        }
        .play-icon {
          width: 35px;
          height: 35px;
          svg {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }

  .MuiPaper-root {
    z-index: 10000;
  }
}
