.search-page {
  color: white;
  width: 95%;
  margin: auto;
  height: calc(
    100vh - var(--navbar-height) - 1.5rem - var(--navbar-height) - 1rem
  );
  overflow: hidden;
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 1rem;
  margin-top: 1rem;
  .trending-shows {
    grid-area: shows;
    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      font-family: Roboto;
      color: #fff;
    }

    .shows-listing {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  .search-left {
    height: 100%;
    width: 100%;
    overflow: hidden;
    .tags {
      display: flex;
      gap: 10px;
      max-height: 60px;
      margin-bottom: 0.6rem;

      .tag {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        padding: 8px 1.7rem;
        border-radius: 3rem;
        background: #212121;
        color: #8d8d8d;
        font-family: Roboto;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 24.123px */
        &.active {
          background-color: white;
          color: #212121;
          font-weight: 600;
        }
      }
    }

    .search-shows-wrapper {
      color: #e3e3e3;
      font-family: Roboto;
      font-weight: 700;
      height: 100%;
      margin-top: 1rem;
      h3 {
        a {
          color: #fff;
          font-family: Roboto;
          font-size: 1rem;
          font-weight: 500;
          text-decoration-line: underline;
          cursor: pointer;
          // margin-right: 20px;
          font-size: 0.9rem;
        }
      }

      .search-shows {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0.9rem;
        margin-top: 1rem;
        height: 80%;
        overflow-y: auto;
        box-sizing: content-box;
        .publish-content-card {
          width: 9.5rem;
        }
      }
    }
  }
  .search-right {
    height: 100%;
    overflow: hidden;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        color: #e3e3e3;
        font-family: Poppins;
        font-size: 1.5rem;
        font-weight: 600;
      }
      button {
        color: #fff;
        font-family: Roboto;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;

        svg {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }

    .episode-listing-wrapper {
      height: 90%;
      overflow-y: auto;
      margin-top: 1rem;
      padding-right: 1rem;

      .episode-listing-card {
        margin-top: 0.9rem;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    display: flex;
    flex-direction: column;
    gap: 0px;
    height: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    .search-left {
      grid-area: shows;
      height: 100%;
      width: 100%;
      overflow: hidden;
      grid-area: left;

      .tags {
        margin-top: 1rem;
        overflow-x: auto;
      }

      .search-shows-wrapper {
        margin-top: 2rem;

        .search-shows {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          .publish-content-card {
            min-width: 9.5rem;
          }
        }
      }
    }
    .search-right {
      margin-top: 2rem;

      .episode-listing-wrapper {
        padding-right: 0rem;
      }
    }
  }
}
