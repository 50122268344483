.view-all-page {
  color: white;
  width: 95%;
  margin: auto;
  color: #e3e3e3;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  overflow-x: hidden;
  .title {
    display: flex;
    align-items: center;
    gap: 10px;
    button {
      margin: 0;
      padding: 0;
      padding-left: 0;
      min-width: auto;

      // background-color: rebeccapurple;
    }
    svg {
      margin: 0;
      padding: 0;
      // background-color: red;
      cursor: pointer;
    }
  }
  .shows-container {
    margin-top: 2rem;
    display: flex;
    margin-left: 1rem;
    flex-wrap: wrap;
    gap: 2rem;
  }

  @media (max-width: $breakpoint-mobile) {
    font-size: 1.5rem;
    width: 95%;
    .shows-container {
      gap: 0.6rem;
      row-gap: 1.5rem;
    }
  }
}
