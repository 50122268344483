.navbar {
  position: fixed;
  top: 0;
  width: calc(100% - var(--sidebar-width));
  height: var(--navbar-height);
  z-index: 100;
  background: transparent;
  backdrop-filter: blur(30px);
  transition: 200ms;

  &.search-open {
    .content {
      .navbarMenu {
        display: none;
      }
      .search {
        width: 50%;
        max-width: 50%;
        transition: width 0.3s;
      }
    }
  }

  .content {
    max-width: 2000px;
    margin: auto;
    width: 98%;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;

    .back-btn-for-mobile {
      display: none;
    }
  }

  .logo {
    color: #fff;
    font-family: "Bebas Neue";
    font-size: 2rem;
    font-weight: 400;
    text-decoration: none;
  }

  ::placeholder {
    color: white;
    opacity: 0.7;
  }
  .menuIcon {
    display: none;
  }

  .search {
    width: 50%;
    height: 2.5rem;
    border-radius: 42px;
    border: 1.5px solid #fff;
    display: flex;
    align-items: center;
    padding-left: 1.2rem;
    gap: 10px;
    margin-left: 1rem;

    input {
      height: 100%;
      width: 90%;
      background-color: transparent;
      border: none;
      outline: none;
      color: #fff;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 400;
      transition: 0.3s;
    }
  }

  .profile-main-wrapper {
    position: relative;
    width: 12rem;
    height: calc(var(--navbar-height));

    .profile-wrapper {
      position: absolute;
      top: 0;
      width: 12rem;
      height: 100%;
      overflow: hidden;
      transition: 250ms;
      border: 2px solid transparent;

      &.open {
        height: 8rem;
        border-radius: 15px;
        background-color: #0f0f0f;
        border: 2px solid #2a2a2a;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.85);

        .profile .arrow-icon {
          transform: rotate(180deg);
        }

        .profile-menu div {
          border-top: 1px solid #2a2a2a;
          opacity: 1;
          background-color: #0f0f0f;
          font-size: 1.1rem;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        height: 4rem;
        padding: 0 10px;
        color: #fff;
        font-family: Roboto;
        font-size: 1.1rem;
        font-weight: 500;
        cursor: pointer;

        span {
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .profile-menu {
        text-decoration: none;
        color: white;
        white-space: nowrap;

        div {
          width: 12rem;
          display: flex;
          height: 4rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          opacity: 0;
          color: #fff;
          font-family: Roboto;
          font-size: 1.188rem;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .menuIcon {
      display: block;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    display: flex;
    width: 100%;
    top: 2;
    position: relative;
    margin-top: 1rem;
    button {
      display: none;
    }
    .search {
      display: none;
    }
    &.search-open {
      .content {
        width: 95%;
        overflow: hidden;

        .search {
          display: block !important;
          width: 80% !important;
          max-width: 80% !important;
          height: 40px;
          border: 1px solid #fff;
          background-color: #0f0f0f;
        }

        input {
          background-color: #0f0f0f;
          height: 40px;
        }

        .back-btn-for-mobile {
          display: block;
          width: 10%;
          height: 40px;
          position: relative;
          transform: scale(0.9);
          button {
            display: block;
          }
        }
        .profile-main-wrapper {
          display: none;
        }
        // }
        .menu-wrapper {
          display: none;
        }

        ::placeholder {
          opacity: 0.7;
        }
      }
    }

    .menuIcon, .logo {
      display: block;
    }
    
  }
}
