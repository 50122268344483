.navbarMenu {
  color: var(--heading, #e0e0e0);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: var(--navbar-height);
  display: flex;

  a {
    float: left;
    font-size: 1.1rem;
    color: white;
    text-align: center;
    padding: 14px 1.4rem;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    background-color: transparent;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0.125rem;
      width: 0%;
      background-color: white;
      transition: 0.3s width;
    }

    &:hover {
      background: linear-gradient(
        90deg,
        rgba(217, 217, 217, 0) 0%,
        rgba(217, 217, 217, 0.1) 10%,
        rgba(217, 217, 217, 0.15) 20%,
        rgba(217, 217, 217, 0.2) 30%,
        rgba(217, 217, 217, 0.25) 40%,
        rgba(217, 217, 217, 0.25) 50%,
        rgba(217, 217, 217, 0.2) 60%,
        rgba(217, 217, 217, 0.15) 70%,
        rgba(217, 217, 217, 0.1) 85%,
        rgba(217, 217, 217, 0) 100%
      );
      &::after {
        width: 90%;
      }
    }
  }

  .dropdown {
    float: left;
    position: relative;
    color: var(--heading, #e0e0e0);
    height: 100%;
    display: flex;
    align-items: center;

    .dropbtn {
      font-size: 1.1rem;
      border: none;
      outline: none;
      color: white;
      padding: 14px 1.5rem;
      background-color: inherit;
      font-family: inherit;
      display: flex;
      align-items: center;

      span {
        margin-left: 0.7rem;
        svg {
          transform: rotate(180deg);
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 0.125rem;
        width: 0;
        background-color: white;
        transition: 0.3s width;
      }
    }
    .dropdown-content-wrapper {
      position: absolute;
      top: 100%;
      height: 8px;
      width: 100%;
      .dropdown-content,
      .dropdown-content-big {
        margin-top: 8px; // while changing the margin-top change height of parent too both should be equal
        display: none;
        position: absolute;
        background-color: #383838;
        border-radius: 0.25rem;
        box-shadow: 0px 5.89792px 5.89792px 0px rgba(0, 0, 0, 0.25);
        z-index: 1000;
        // top: 105%;
        height: auto;
        padding: 12px 10px;
        box-sizing: border-box;
        min-width: 160px;
        a {
          width: 100%;
          padding: 12px 10px;
          text-decoration: none;
          display: block;
          min-width: 8rem;
          text-align: left;
          color: var(--heading, #e0e0e0);
          font-family: Roboto;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 0.25rem;

          &:hover {
            background-color: rgba(224, 224, 224, 0.1);
            &::after {
              width: 0px;
            }
          }
        }
      }
    }
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(217, 217, 217, 0) 0%,
        rgba(217, 217, 217, 0.1) 10%,
        rgba(217, 217, 217, 0.15) 20%,
        rgba(217, 217, 217, 0.2) 30%,
        rgba(217, 217, 217, 0.25) 40%,
        rgba(217, 217, 217, 0.25) 50%,
        rgba(217, 217, 217, 0.2) 60%,
        rgba(217, 217, 217, 0.15) 70%,
        rgba(217, 217, 217, 0.12) 80%,
        rgba(217, 217, 217, 0.05) 90%,
        rgba(217, 217, 217, 0) 100%
      );
      .dropbtn {
        &::after {
          width: 90%;
        }
        span {
          svg {
            transition: 0.3s;
            transform: rotate(0);
          }
        }
      }
      .dropdown-content {
        display: block;
      }
      .dropdown-content-big {
        display: grid;
        width: 60vw;
        grid-template-columns: auto auto auto auto;
      }
    }
  }

  @media (max-width: "1350px") {
    a {
      padding: 14px 1rem;
    }

    .dropbtn {
      padding: 14px 1rem;
    }
  }
  @media (max-width: "1200px") {
    a {
      padding: 14px 10px;
    }

    .dropbtn {
      padding: 14px 10px;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    display: none;
  }
  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
}
