// @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,500;1,700;1,900&display=swap');

.sidebar {
  overflow: hidden;
  position: fixed;
  top: 0px;
  width: var(--sidebar-width);
  transition: 200ms;
  background-color: $bg-color-secondary;
  z-index: 1000;
  color: $color-primary;
  height: 100vh;
  border-right: 1px solid #2a2a2a;
  .logo {
    color: #e3e3e3;
    width: 100%;
    height: 5.6rem;
    font-family: "Bebas Neue";
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: grid;
    align-items: center;
    grid-template-columns: minmax(auto, 8.5rem) auto;
    span{
        text-align: center;
    }
    button {
      justify-self: flex-end;
      height: 3.75rem;
      width: 3.75rem;
    }
  }

  a {
    max-width: 100%;
    height: 4.6rem;
    color: #fff;
    list-style: none;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-left: 1rem;
    gap: 0.625rem;
    text-decoration: none;
    font-family: "Roboto";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    transition: 200ms;
    .icon {
      height: 2rem;
      width: 2rem;
      background-color: white;
      border-radius: 50%;
      display: grid;
      place-items: center;
    }

    &.active,
    &:hover {
      background-color: black;
      &::after {
        content: "";
        height: 100%;
        width: 0.438rem;
        background-color: white;
        position: absolute;
        right: 0;
        border-radius: 0.625rem 0px 0px 0.625rem;
      }
    }

    &.closed {
      justify-content: center;
      padding-left: 0rem;
    }
  }
}
