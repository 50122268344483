.footer-wrapper {
  width: 100%;
  background: #2a2a2a;
  margin-top: 2rem;
  padding-bottom: 5rem;
  .internal-linking {
    z-index: 10;
    width: 90%;
    font-family: Poppins;
    margin: auto;
    color: white;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    align-items: stretch;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;
    .links-parent-wrapper {
      h3 {
        margin-top: 2rem;
        color: #fff;
        font-family: Poppins;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.11381rem;
      }

      .link-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(150px, 250px));
        align-items: stretch;
        .links-child-wrapper {
          h4 {
            margin-top: 2rem;
            color: var(--heading, #e0e0e0);
            font-family: Poppins;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 700;
          }

          .shows {
            margin-top: 1rem;
            width: 90%;
            a {
              margin-top: 8px;
              display: -webkit-box;
              color: var(--heading, #e0e0e0);
              font-family: Poppins;
              font-size: 0.9rem;
              font-style: normal;
              font-weight: 400;
              width: 95%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          P {
            margin-top: 1rem;
            display: flex;
            align-items: center;
            color: #e0e0e0;
            font-family: Poppins;
            font-size: 0.8rem;
            font-style: normal;
            font-weight: 600;
            line-height: 2.47331rem; /* 219.851% */
            text-transform: capitalize;
            gap: 10px;
            cursor: pointer;
            &:hover {
              color: #e0e0e0cd;
            }
          }

          .rotate-arrow {
            svg {
              transition: 0.3s;
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &.collapse {
      height: 200px;
    }

    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $breakpoint-mobile) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .collapse-button {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 200px;
      color: #ff7373;
      font-family: Poppins;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
    }
    &.collapse {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .main-footer {
    width: 100%;
    background: #2a2a2a;
    position: relative;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;

    .content {
      z-index: 10;
      width: 90%;
      font-family: Poppins;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          color: #fff;
          font-family: Poppins;
          font-size: 2rem;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 0.15625rem;
          margin-bottom: 1rem;
        }
        p {
          color: var(--heading, #e0e0e0);
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
        }

        .playstore-wrapper {
          display: none;
        }
      }

      .nav-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 1.4rem;

        h4 {
          color: var(--sub, #b8b8b8);
          font-family: Poppins;
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 500;
          line-height: 2.11381rem; /* 140.92% */
        }
        h3 {
          display: none;
        }

        .wrapper {
          display: flex;
          flex-direction: column;

          a {
            color: var(--heading, #e0e0e0);
            font-family: Poppins;
            font-size: 0.8rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.2rem; /* 197.866% */
            text-decoration: none;
          }

          .link-wapper {
            display: flex;
            flex-direction: column;
          }

          .social-icons {
            margin-top: 1.5rem;
            display: flex;
            gap: 2.5rem;
            button {
              padding: 0;
            }
            svg {
              width: 2rem;
              height: 2rem;
              flex-shrink: 0;
            }
            div {
              p {
                margin-bottom: 0.6rem;
                color: rgba(224, 224, 224, 0.7);
                font-family: Roboto;
                font-size: 0.8rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 1.4rem */
              }
              .play-store {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 50px;
                color: rgba(224, 224, 224, 0.7);
                font-family: Roboto;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                margin-top: 0.3rem;
                span {
                  margin-left: 1rem;
                  width: 30px;
                  height: 30px;
                  background: url("../assets/Images/playStore-icon.png");
                  background-size: 30px 30px;
                }
              }
            }
          }

          img {
            width: 140px;
            position: static;
            margin-top: 1.5rem;
          }
        }
      }

      .child-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        bottom: 0;
        margin-top: 1.4rem;

        a {
          color: var(--sub, #b8b8b8);
          font-family: Poppins;
          font-size: 0.8rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration: none;
        }

        p {
          color: var(--heading, #e0e0e0);
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    @media (max-width: $breakpoint-tablet) {
      .content {
        .heading {
          h3 {
            font-size: 2rem;
          }
          p {
            font-size: 0.9rem;
          }
        }

        .nav-head {
          width: 80%;
          h4 {
            color: var(--sub, #b8b8b8);
            font-size: 1.5rem;
          }

          .wrapper {
            .link-wapper {
              a {
                font-size: 1.2rem;
              }
            }
            .social-icons {
              margin-top: 1.5rem;
              gap: 2rem;
            }
            img {
              width: 100px;
            }
          }
          .desktop {
            display: none;
          }
        }

        .child-footer {
          width: 60%;
          bottom: 0;
          a {
            font-size: 1.125rem;
          }
        }
      }
    }

    @media (max-width: "770px") {
      .content {
        .heading {
          h3 {
            font-size: 2.5rem;
          }
          p {
            font-size: 1rem;
          }
        }

        .nav-head {
          width: 100%;
          h4 {
            font-size: 1.3rem;
          }

          .wrapper {
            .link-wapper {
              a {
                font-size: 1rem;
              }
            }
            .social-icons {
              margin-top: 1.3rem;
              gap: 1.3rem;
            }
            img {
              width: 100px;
            }
          }
          .desktop {
            display: none;
          }
        }

        .child-footer {
          a {
            font-size: 1rem;
          }
        }
      }
    }

    @media (max-width: "470px") {
      height: 60rem;

      img {
        position: absolute;
        width: 70%;
        bottom: 0;
        right: 0;
        z-index: 1;
      }
      .content {
        height: 90%;
        justify-content: flex-start;
        .heading {
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          h3 {
            display: none;
          }
          p {
            text-align: center;
            font-size: 1rem;
            color: var(--sub, #b8b8b8);
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 0.41288rem;
          }
          .playstore-wrapper {
            text-align: center;
            display: block;
            color: var(--sub, #b8b8b8);
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 0.41288rem; /* 41.285% */
            img {
              width: 140px;
              position: static;
              margin-top: 1.5rem;
            }
          }
        }

        .nav-head {
          width: 100%;
          // background-color: red;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;
          height: 70%;
          h3 {
            width: 100%;
            display: block;
            color: #fff;
            font-family: Poppins;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          h4 {
            margin-top: 1.4rem;
            font-size: 1.3rem;
            display: none;
          }

          .wrapper {
            width: 50%;
            .link-wapper {
              margin-top: 0px;
              a {
                margin-top: 0.4rem;
                color: var(--heading, #e0e0e0);
                font-family: Poppins;
                font-size: 0.9rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem; /* 171.429% */
              }
            }
            .social-icons {
              margin-top: 0.3rem;
              gap: 1.3rem;
              svg {
                width: 2rem;
                height: 2rem;
              }
            }
            img {
              display: none;
            }
          }
        }

        .child-footer {
          flex-direction: column;
          gap: 10px;
          a {
            color: var(--sub, #b8b8b8);
            font-family: Poppins;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}
